.root {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.center {
  align-items: center;
}

.closeBackground {
  cursor: pointer;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.404);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.wraper {
  padding: 5px;
  border-radius: 5px;
  opacity: 0;
  min-height: 200;
  transition: opacity 200ms ease-in-out, left 200ms ease-in-out;
  position: relative;
  z-index: 2;
  width: 90vw;
  margin: 0 auto;
  background: white;
  box-shadow: 0 0 10px 0.1px rgba(128, 128, 128, 0.329);
  box-sizing: border-box;

  &[data-fade="visible"] {
    opacity: 1;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.7vw;
  border-bottom: px solid #dee2e6;

  .title {
    font-size: 0.9rem;
    line-height: 0.9rem;
  }
}

.closeButton {
  z-index: 2;
  cursor: pointer;
  background: white;
}

.content {
  z-index: 1;
  height: calc(100% - (1.4vw + 24px));
}
