.root {
  width: 95%;
  max-width: 600px;
  min-height: 300px;
  background: white;
  margin: 0 auto;
  box-shadow: 0.4px 0.2px 12px 1px #202f4659;
  border-radius: #{"max(0.6vw, 5px)"};
  overflow: hidden;
  .header {
    color: #fff;
    text-align: center;
    font-size: 2em;
    line-height: 3rem;
    background: #214983;
  }

  .footer {
    background: #2c3e50;
    height: 5vh;
  }
}

.formLogo {
  font-size: 4rem;
  line-height: 4rem;
  color: #214983;
}

.formContent {
  padding: 0 15%;
  min-height: calc(300px - 2vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .formLogo {
    margin-bottom: 20px;
  }

  .buttons {
    margin-top: 15px;
  }
}

.errorsWraper {
  margin-top: -10px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;

  button {
    width: unset;
    height: unset;
    border: none;
    outline: none;
    background: #214983;
    color: #fff;
    font-size: 1rem;
    line-height: 1rem;
    padding: 8px 11px;
    border-radius: 2px;

    &[disabled]:not([disabled="false"]) {
      cursor: not-allowed;
      pointer-events: none;
      background: grey;
    }
  }
}
