.table {
  width: 100%;
  max-width: 1000px;
  min-height: 300px;
  margin: 0 auto;
  position: relative;

  > .wrap {
    width: 100%;
    background: white;
    overflow-x: scroll;
    border-radius: #{"max(0.6vw, 5px)"};
    box-shadow: 0.4px 0.2px 12px 1px #202f4659;
  }

  table {
    width: 1000px;
    border-spacing: 0;
    border-collapse: collapse;

    td,
    th {
      padding: 5px;
      text-align: center;
      font-size: 0.9em;
      line-height: 0.9em;
    }

    thead {
      background: #214983;
      color: #fff;

      th {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }

    tbody {
      tr:nth-child(2n) {
        background: #ecf0f1;
      }
    }
  }

  .addons {
    position: absolute;

    height: 25px;
    top: -35px;
    left: 0;

    svg {
      cursor: pointer;
      color: #fff;
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }
}

@media screen and (min-width: 769px) {
  .table {
    table {
      width: 100%;
    }

    .addons {
      width: 25px;
      height: unset;
      top: 0;
      left: unset;
      right: -35px;
    }
  }
}
