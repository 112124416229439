.root {
  width: 100%;

  .listUploaded {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .uploaded {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (min-width: 769px) {
      &:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)):not(:nth-child(6)):not(:nth-child(7)):not(:nth-child(8)):not(:nth-child(9)) {
        margin-top: 20px;
      }

      &:not(:nth-child(9n + 1)) {
        margin-left: 15px;
      }

      width: calc((100% - (8 * 15px)) / 9);
    }

    @media screen and (max-width: 768px) {
      &:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
        margin-top: 20px;
      }

      &:not(:nth-child(3n + 1)) {
        margin-left: 15px;
      }

      width: calc((100% - (2 * 15px)) / 3);
    }

    .pic {
      width: 100%;
      height: 100%;
      position: relative;
      box-shadow: 0 0 10px 0.2px #a5a5a561;
      border-bottom: 3px solid #214983;

      &:hover svg {
        display: block;
      }

      .thumbnail {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        img {
          width: 100%;
        }
      }

      svg {
        position: absolute;
        top: -0.4em;
        right: -0.4em;
        color: #ff4040;
        display: none;
        cursor: pointer;
      }
    }

    .comment {
      margin-top: 5px;
      height: 2em;
      padding: 5px 2px;
      width: 100%;
      border: none;
      outline: none;
      font-size: 0.8rem;
      line-height: 0.8rem;
      color: rgba(0, 0, 0, 0.545);
      border: 1px solid rgba(146, 146, 146, 0.425);
    }

    .type {
      width: 100%;
      height: 20px;
      display: flex;
      margin-top: 10px;
      position: relative;

      .star {
        font-size: 1rem;
        line-height: 1rem;
        color: #ff4040;
        position: absolute;
        left: -0.5rem;
      }
    }

    select {
      text-align: center;
      width: 100%;
      height: 100%;
      outline: none;
      border: 1px solid rgba(146, 146, 146, 0.425);
      font-size: 0.7rem;
      line-height: 0.7rem;
    }
  }

  input[type="file"] {
    display: none;
  }

  label {
    width: 100%;
    height: 90px;
    .upBox {
      border: 2px dashed #878787;
      width: 90px;
      height: 90px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        font-size: 4em;
        color: #292929;
      }
    }
  }
}
