.root {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid rgba(128, 128, 128, 0.37);

  div[data-role="field-group"] {
    padding: 0px;
    width: 100%;
  }

  div[data-role="field-group-block"] {
    width: 100%;
    padding: 5px 0px;

    > * {
      padding-bottom: 5px;
      padding-top: 5px;

      &:not(:first-child) {
        border-top: 1px solid rgba(128, 128, 128, 0.37);
      }

      &:first-child {
        padding-top: 0px;
      }

      &:last-child {
        padding-bottom: 0px;
      }
    }
  }

  [data-hor-align="center"] {
    display: flex;
    justify-content: center;
  }

  [data-role="note"] {
    font-size: 0.8rem;
    line-height: 0.95rem;
    color: gray;
    padding: 5px;
    width: 100%;
    a {
      color: #39c;
      text-decoration: none;
    }
  }

  label {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 5px;
    input {
      margin: 0;
    }

    span {
      height: 100%;
      display: block;
      margin-left: 4px;
      font-size: 0.8rem;
      line-height: 1rem;
    }
  }

  @media screen and (max-width: 768px) {
    [data-divider]:not([data-divider="false"]) {
      > * {
        &:not(:first-child) {
          border-top: 1px solid rgba(128, 128, 128, 0.37);
        }
      }
    }
  }

  input {
    height: 25px;
  }

  textarea {
    height: 5em;
  }

  textarea,
  input[type="password"],
  input[type="text"],
  input[type="number"],
  input:not([type]),
  input[type=""] {
    padding: 5px 10px;
    width: 100%;
    border: none;
    outline: none;
    font-size: 0.8rem;
    line-height: 1.1rem;
    color: rgba(0, 0, 0, 0.545);
  }

  select {
    padding: 5px 10px;
    border: none;
    outline: none;
    width: 100%;
    height: 25px;
    font-size: 0.8rem;
    line-height: 0.8rem;
  }

  .inputWrap {
    flex: 1;
    height: 100%;
    border-left: 1px solid rgba(128, 128, 128, 0.37);

    > div[data-role="addon-group"] {
      display: flex;
      align-items: center;

      > div[data-role="addon"] {
        width: unset;
        flex: 1;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.9rem;
        line-height: 0.9rem;
        color: #fff;

        &[data-mode="primary"] {
          background: #214983;
        }
        &[data-mode="danger"] {
          background: #ff4040;
        }

        &[data-addon="pointer"] {
          cursor: pointer;
        }
        &[aria-disabled]:not([aria-disabled="false"]) {
          cursor: not-allowed;
          pointer-events: none;
          background: grey;
        }
      }
    }

    > * {
      &:not(:first-child) {
        border-top: 1px solid rgba(128, 128, 128, 0.37);
      }
    }
  }
}

.root + .root {
  margin-top: 2%;
}

.label {
  &[data-role="text"] {
    width: #{"max(20%, 160px)"};
  }

  &[data-role="icon"] {
    color: var(--icon-color);
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  height: 100%;
  display: flex;
  align-items: center;
  font-size: 0.7rem;
  line-height: 1rem;
  padding: 0 1%;

  sup {
    color: #ff4040;
    font-size: 0.8rem;
    line-height: 0.8rem;
  }
}

@media screen and (min-width: 769px) {
  .root {
    select {
      padding: 0px 10px;
    }

    div[data-role="field-group"] {
      display: flex;
      align-items: center;

      &[data-wrap]:not([data-wrap="false"]) {
        flex-wrap: wrap;
      }
    }

    [data-divider]:not([data-divider="false"]) {
      > * {
        &:not(:first-child) {
          border-left: 1px solid rgba(128, 128, 128, 0.37);
        }
      }
    }

    .inputWrap {
      display: flex;
      align-items: center;

      > div[data-role="addon-group"] {
        > div[data-role="addon"] {
          width: 25px;
        }
      }

      > * {
        &:not(:first-child) {
          border-top: unset;
          border-left: 1px solid rgba(128, 128, 128, 0.37);
        }
      }
    }

    label {
      span {
        white-space: nowrap;
      }
    }
  }
}
