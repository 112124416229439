.root {
  position: fixed;
  top: 10px;
  right: 10px;

  svg {
    font-size: 4rem;
    line-height: 4rem;
    color: #fff;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    & {
      position: absolute;
      top: 5px;
    }
    svg {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }
}
