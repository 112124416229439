.root {
  font-size: 0.8rem;
  line-height: 0.8rem;
  color: #ff4040;

  .itemError {
    & + .itemError {
      margin-top: 5px;
    }
  }
}
