.page {
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: 40px 0;
}
.itemLink {
  color: #39c;
  text-decoration: none;
}

.actions {
  button {
    width: unset;
    height: unset;
    border: none;
    outline: none;
    background: #39c;
    color: #fff;
    font-size: 1rem;
    line-height: 1rem;
    padding: 8px 11px;
    border-radius: 2px;
    cursor: pointer;

    &[data-disabled]:not([data-disabled="false"]) {
      cursor: not-allowed;
      background: #7f8c8d;
      color: #2c3e50;
    }
  }
}

.sitesWrap {
  padding: #{"max(2%, 5px)"} 10px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .select {
    width: 100%;
  }

  .buttons {
    margin-top: 10px;
    width: 100%;

    button {
      width: 100%;
      height: unset;
      border: none;
      outline: none;
      background: #39c;
      color: #fff;
      font-size: 1rem;
      line-height: 1rem;
      padding: 8px 11px;
      border-radius: 2px;
      cursor: pointer;

      &[disabled]:not([disabled="false"]) {
        cursor: not-allowed;
        background: #7f8c8d;
        color: #2c3e50;
      }
    }
  }
}
