.root {
  width: 95%;
  max-width: 1000px;
  min-height: 300px;
  background: white;
  margin: 0 auto;
  box-shadow: 0.4px 0.2px 12px 1px #202f4659;
  border-radius: #{"max(0.6vw, 5px)"};
  overflow: hidden;

  .header {
    color: #fff;
    text-align: center;
    font-size: 2em;
    line-height: 3rem;
    background: #214983;
    position: relative;

    svg {
      color: #fff;
      position: absolute;
      top: calc(50% - 0.5rem);
      left: 10px;
      font-size: 1rem;
      line-height: 1rem;
      cursor: pointer;
    }
  }

  .footer {
    position: relative;
    background: #2c3e50;
    height: 5vh;

    svg {
      color: #fff;
      position: absolute;
      top: calc(50% - 0.5rem);
      left: 10px;
      font-size: 1rem;
      line-height: 1rem;
      cursor: pointer;
    }
  }
}

.formTab {
  padding: 2% 0;
}

.generalSections {
  .generalSection {
    & + .generalSection {
      margin-top: #{"max(3%,30px)"};
    }

    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > .title {
        font-size: 1.5rem;
        line-height: 1.5rem;
        color: #808080ba;
      }

      padding-bottom: 5px;
    }

    > .title {
      font-size: 1.5rem;
      line-height: 1.5rem;
      color: #808080ba;
      padding-bottom: 5px;
    }

    .fields {
      margin-top: 1%;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
      padding: 2% 0;
      button {
        width: unset;
        height: unset;
        cursor: pointer;
        border: none;
        outline: none;
        background: #39c;
        color: #fff;
        font-size: 1rem;
        line-height: 1rem;
        padding: 8px 11px;
        border-radius: 2px;
      }
    }

    .errorsWrapper {
      margin-top: -2.5%;
    }
  }
}

.rooms {
  .room {
    padding-left: 10px;
    .divider {
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
      height: 1px;
      font-size: 1rem;
      line-height: 1rem;
      color: #808080ba;
      display: flex;
      align-items: center;

      & > div {
        background: rgba(128, 128, 128, 0.562);
      }

      & > div:nth-child(1) {
        width: #{"max(5%, 30px)"};
        height: 100%;
      }

      & > div:nth-child(2) {
        background: transparent;
        width: #{"max(7%, 70px)"};
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & > div:nth-child(3) {
        height: 100%;
        flex: 1;
      }

      & > div:nth-child(4) {
        background: transparent;
        width: #{"max(7%, 70px)"};
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & > div:nth-child(5) {
        width: #{"max(5%, 30px)"};
        height: 100%;
      }
    }

    .addons {
      display: flex;
      align-items: center;

      .addon {
        width: 30px;
        height: 30px;
        cursor: pointer;
        font-size: 1rem;
        line-height: 1rem;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;

        &[data-mode="primary"] {
          color: #214983;
        }
        &[data-mode="danger"] {
          color: #ff4040;
        }

        &[aria-disabled="true"] {
          cursor: not-allowed;
          pointer-events: none;
          color: grey;
        }
      }
    }
    & + .room {
      margin-top: 3%;
    }
  }
}

.typesSection {
  & + .typesSection {
    margin-top: 3%;
  }

  > .title {
    font-size: 1.5rem;
    line-height: 1.5rem;
    color: #808080ba;
    padding-bottom: 15px;
    border-bottom: 4px solid #34495e;
  }

  .uploadWrapper {
    padding: 3% 0;
  }
}
