.root {
  width: 100%;
  min-height: 300px;
  background: white;
}

.heading {
  width: 100%;
  display: flex;

  & > div:not(:first-child) {
    border-left: 1px solid rgba(128, 128, 128, 0.496);
  }
}

.tabhead {
  cursor: pointer;
  border-bottom: 1px solid rgba(168, 168, 168, 0.377);
  height: 100%;
  padding: 1% 2%;
  font-size: 1rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  box-shadow: 0px 2px 4px 0.1px #8a8a8a38;

  &[data-status="actived"] {
    box-shadow: unset;
    color: gray;
  }

  &[data-disabled="true"] {
    cursor: not-allowed;
    pointer-events: none;
    background: #80808036;
  }
}

.content {
  padding: 1.5% #{"max(2%, 10px)"};
  font-size: 0.8rem;
  line-height: 0.8rem;
}
