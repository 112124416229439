.root {
  padding: #{"max(2%, 5px)"} 10px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .select {
    width: 100%;
  }

  .buttons {
    margin-top: 10px;
    width: 100%;

    button {
      width: 100%;
      height: unset;
      border: none;
      outline: none;
      background: #39c;
      color: #fff;
      font-size: 1rem;
      line-height: 1rem;
      padding: 8px 11px;
      border-radius: 2px;
      cursor: pointer;

      &[disabled]:not([disabled="false"]) {
        cursor: not-allowed;
        background: #7f8c8d;
        color: #2c3e50;
      }
    }
  }
}

.siteOption {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &[data-disabled]:not([data-disabled="false"]) {
    pointer-events: none;
    > span {
      color: gray;
    }
  }

  > span:last-child {
    margin-left: 10px;
    font-size: 0.8rem;
    line-height: 0.8rem;
    font-style: italic;
    text-transform: uppercase;
    font-weight: bold;

    &[data-status="new"] {
      color: #2c3e50;
    }

    &[data-status="running"] {
      color: #39c;
    }

    &[data-status="success"] {
      color: #2ecc71;
    }

    &[data-status="fail"] {
      color: #ff4040;
    }
  }
}
